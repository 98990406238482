import { httpGet } from './http'

export const locationOptions = {
    timeout: 15000,
    maximumAge: 0,
    enableHighAccuracy: true,
}

export function getLocation(onError: any, onResult: (currentLocation: any) => void) {
    try {
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const currentLocation = {
                    coordinates: [position.coords.latitude, position.coords.longitude],
                    altitude: position.coords.altitude,
                    accuracy: position.coords.accuracy,
                    speed: position.coords.speed,
                    altitude_accuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    snapshot_timestamp: position.timestamp,
                }

                onResult(currentLocation)
            },
            function (error) {
                switch (error.code) {
                    case 1:
                        onError(
                            'Geo-Snapshot: permission was denied, allow location permission in your settings'
                        )
                        break
                    case 2:
                        onError('Geo-Snapshot: position unavailable')
                        break
                    default:
                        onError('Geo-Snapshot: snapshot failed')
                        break
                }
                console.log(error.code, error.message)
                httpGet(`/landing/status?geo-error=${error.message}&error-code=${error.code}`)
            },
            locationOptions
        )
    } catch (e) {
        console.log(e)
        onError('Error:', e.toString())
        httpGet(`/landing/status?geo-error=${e?.message}&error-code=${e?.code}`)
    }
}
