// import * as platform from 'platform'
import * as deviceDetect from 'react-device-detect'
var platform = require('platform')

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
        return 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (iOS()) {
        return 'iOS'
    }

    return 'unknown'
}

function iOS() {
    var isiOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
        isiOS
    )
}

function iOSversion() {
    if (iOS()) {
        // <-- Use the one here above
        if (window.indexedDB) {
            return 'iOS 8 and up'
        }
        if (window.SpeechSynthesisUtterance) {
            return 'iOS 7'
        }
        if (window.webkitAudioContext) {
            return 'iOS 6'
        }
        if (window?.matchMedia) {
            return 'iOS 5'
        }
        if (window.history && 'pushState' in window.history) {
            return 'iOS 4'
        }
        return 'iOS 3 or earlier'
    }

    return null
}

function getProduct() {
    switch (true) {
        case deviceDetect.isIPad13:
            return 'ipad'
        case deviceDetect.isSmartTV:
            return 'smarttv'
        case deviceDetect.isWinPhone:
            return 'winphone'
        case deviceDetect.isTablet:
            return 'tablet'
        case deviceDetect.isDesktop:
            return 'desktop'
        case deviceDetect.isIPhone13:
            return 'iphone'
        case deviceDetect.isMacOs:
            return 'mac'
        case deviceDetect.isIPod13:
            return 'ipod'
        case deviceDetect.isConsole:
            return 'console'
    }
}

export function getDeviceInfo() {
    const deviceResult = deviceDetect.deviceDetect()
    const osTry = getMobileOperatingSystem()
    const iOSversionTry = iOSversion()
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return {
        userAgent: deviceResult.userAgent || userAgent,
        name: platform.name || deviceResult.browser_name, // 'Safari'
        version: platform.version || deviceResult.browserVersion || deviceResult.browserFullVersion, // '5.1'
        product: platform.product || getProduct(), // 'iPad'
        manufacturer: platform.manufacturer, // 'Apple'
        layout: platform.layout, // 'WebKit'
        os: deviceResult.osName || platform.os?.family || osTry || deviceDetect.osName, // 'iOS 5.0'
        osVersion: platform.os?.version || iOSversionTry || deviceDetect.osVersion,
        description: platform.description, // 'Safari 5.1 on Apple iPad (iOS 5.0)'
    }
}
