import { IconButton, ThemeProvider, Theme, StyledEngineProvider, Tooltip } from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'
import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import { message } from 'antd'
import Badge from '@mui/material/Badge'
import { httpGet, httpPost } from '../../services/http'
import { toDate } from 'date-fns'
import { userLocation } from '../../lib/interfaces'
import { formatText, layoutDictKeyFormat } from '../../lib/dateFunctions'
import { useSessionIfExists } from '../../hooks/useAuth'
import { navigate } from 'gatsby'
import { getDeviceInfo } from '../../utils/deviceInfo'
import dayjs from 'dayjs'
import { locationOptions } from '../../services/geoLocation'

export const snapshotStates = {
    NO_SNAPSHOT: 'no_snapshot',
    LOADING: 'loading',
    HAS_SNAPSHOT: 'has_snapshot',
}

const customTheme = (outerTheme) => ({
    ...outerTheme,
    palette: {
        ...outerTheme.palette,
        primary: {
            main: '#D7E6F5',
            contrastText: '#fff',
            light: '#E7F6DD',
            dark: '#50795D',
        },
        secondary: {
            main: '#ab7e1d',
            contrastText: '#fff',
            light: '#E7F6DD',
            dark: '#50795D',
        },
    },
})

function populateUserLocationDates(userLocationDates, setUserLocationDates, position) {
    if (
        !userLocationDates.find(
            (uld) =>
                formatText(toDate(new Date(uld.target_date)), layoutDictKeyFormat) ===
                formatText(toDate(new Date(position.timestamp)), layoutDictKeyFormat)
        )
    ) {
        setUserLocationDates([
            ...userLocationDates,
            { target_date: toDate(new Date(position.timestamp)), location_count: 1 },
        ])
    } else {
        setUserLocationDates(
            userLocationDates.map((uld) => {
                if (
                    formatText(toDate(new Date(uld.target_date)), layoutDictKeyFormat) ===
                    formatText(toDate(new Date(position.timestamp)), layoutDictKeyFormat)
                ) {
                    return {
                        ...uld,
                        location_count: uld.location_count + 1,
                    }
                }
                return uld
            })
        )
    }
}

export function GeoSnapshooter({ isBackgroundLoading, setUserLocationDates, userLocationDates }) {
    const [snapshotState, setSnapshotState] = useState<string>(snapshotStates.NO_SNAPSHOT)
    const [count, setCount] = React.useState(0)
    const { session } = useSessionIfExists()

    async function getSnapshotCount() {
        const res = await httpGet<{ locations: userLocation[] }>(
            `/user-locations/${formatText(toDate(new Date()), layoutDictKeyFormat)}`
        )
        if (!res.data) return
        const locations = res.data.locations
        const newCount = locations.filter((l) => l.is_geo_snapshot).length
        setCount(newCount)
        if (newCount > 0) {
            setSnapshotState(snapshotStates.HAS_SNAPSHOT)
        } else {
            setSnapshotState(snapshotStates.NO_SNAPSHOT)
        }
    }

    useEffect(() => {
        getSnapshotCount()
    }, [isBackgroundLoading])

    async function onPosition(position: GeolocationPosition) {
        const deviceResult = getDeviceInfo()
        const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            altitude: position.coords.altitude,
            accuracy: position.coords.accuracy,
            speed: position.coords.speed,
            altitude_accuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            snapshot_timestamp: position.timestamp,
            browser_name: deviceResult.name,
            browser_version: deviceResult.version,
            user_agent: deviceResult.userAgent,
            os_name: deviceResult.os,
            os_version: deviceResult.osVersion,
            product: deviceResult.product,
            manufacturer: deviceResult.manufacturer,
            layout: deviceResult.layout,
            description: deviceResult.description,
        }
        const response = await httpPost<{ location_id: string }>(`/locations/`, currentLocation)
        const locationId = response?.data?.location_id
        if (
            true
            // session?.user?.feature_flags &&
            // session?.user?.feature_flags?.split(' ')?.includes('sms_location')
        ) {
            navigate(`/app/locations/${locationId}`)
            return
        } else {
            await getSnapshotCount()
            setSnapshotState(snapshotStates.HAS_SNAPSHOT)
            populateUserLocationDates(userLocationDates, setUserLocationDates, position)
            message.success('Geo-Snapshot taken')
        }
    }

    async function takeSnapshot() {
        setSnapshotState(snapshotStates.LOADING)
        if (!('geolocation' in navigator)) {
            message.error('Geo-Snapshots are not supported on your device')
            setSnapshotState(snapshotStates.NO_SNAPSHOT)
        }
        try {
            if (
                session?.user?.email?.startsWith('autotest') &&
                session?.user?.email?.endsWith('@ll33a.dk')
            ) {
                onPosition({
                    timestamp: dayjs().unix() * 1000,
                    coords: {
                        accuracy: 10,
                        altitude: null,
                        altitudeAccuracy: null,
                        heading: null,
                        latitude: 55.715346,
                        longitude: 12.5499,
                        speed: null,
                    },
                })
                return
            }
            navigator.geolocation.getCurrentPosition(
                onPosition,
                function (error) {
                    switch (error.code) {
                        case 1:
                            message.error(
                                'Geo-Snapshot: permission was denied, allow location permission in your settings'
                            )
                            break
                        case 2:
                            message.error('Geo-Snapshot: position unavailable')
                            break
                        default:
                            message.error('Geo-Snapshot: snapshot failed')
                            break
                    }
                    console.log(error.code, error.message)
                    setSnapshotState(snapshotStates.NO_SNAPSHOT)
                    httpGet(`/landing/status?geo-error=${error.message}&error-code=${error.code}`)
                },
                locationOptions
            )
        } catch (e) {
            console.log(e)
            message.error('Error:', e.toString())
            setSnapshotState(snapshotStates.NO_SNAPSHOT)
            httpGet(`/landing/status?geo-error=${e?.message}&error-code=${e?.code}`)
        }
    }

    function renderIcon() {
        switch (snapshotState) {
            case snapshotStates.NO_SNAPSHOT:
                return (
                    <Tooltip title="Take Geo-Snapshot">
                        <IconButton
                            style={{ marginRight: '15px', marginLeft: '15px' }}
                            color="primary"
                            className="autotest-snapshooter-button"
                            onClick={takeSnapshot}
                            edge="end"
                            aria-label="location-snapshot"
                            size="large"
                        >
                            <PlaceIcon style={{ color: '#D7E6F5' }} />
                        </IconButton>
                    </Tooltip>
                )
            case snapshotStates.LOADING:
                return <CircularProgress />
            case snapshotStates.HAS_SNAPSHOT:
                return (
                    <Tooltip title="Take Geo-Snapshot">
                        <IconButton
                            className="autotest-snapshooter-button"
                            style={{ marginRight: '15px', marginLeft: '15px' }}
                            color="primary"
                            onClick={takeSnapshot}
                            edge="end"
                            aria-label="location-snapshot"
                            size="large"
                        >
                            <Badge color="secondary" badgeContent={count}>
                                <WhereToVoteIcon style={{ color: '#D7E6F5' }} />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )
        }
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTheme}>{renderIcon()}</ThemeProvider>
        </StyledEngineProvider>
    )
}
